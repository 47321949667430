import React from "react";
/*
If you want to update the documentation, this is the general structure:
sections = [
  {
    title: str
    description: str | JSX,
    subsections: [
      {
        title: str,
        description: str,
        route (optional): str - see examples,
        type (optional): str see examples,
        params (optional): {
          str: str (name: description)
        }
      }
    ]
  }
]
*/



const sections = [
  {
    title: "General Information",
    description: <>The goal of the QIS API is to maintain an internal representation of our portfolio. While our brokerage account tracks our "portfolio" as a whole,
                  the API allows us to break it down futher. At any point in time, we should be able to answer questions such as the following: <ul>
                  <li>Which strategies placed which trades?</li>
                  <li>What positions belong to which strategies?</li>
                  <li>How much equity/cash belongs to each strategy/investor?</li>
                  <li>How is each investor's equity split amongst strategies?</li>
                  </ul>
                  ... and so forth. As such, operations such as placing trades and adding new strategies need to be done properly through the API to ensure things are tracked as intended. </>,
    subsections: [
      {
        title: "Trades",
        description: "All trades must be placed through the QIS API and attributed to a particular strategy. Even if you're just YOLOing, create a YOLO strategy \
               and use it to place your trades. We want every single trade and every single dollar in our brokerage account to be accounted for."
      },
      {
        title: "Leverage",
        description: "All strategies have an exposure cap of 2x their equity. Exposure for strategies is calculated as the sum of the absolute value of each position's exposure. In other words, \
              short positions and long positions both count as positive exposure."
      },

    ]
  },
  {
    title: "Using the API Client",
    description: <> 
      <div style={{margin: '10px 0px'}}>Assuming you have access to the QIS GitLab, you can use the API client via the livelibrary python package.</div>
      <strong style={{marginRight: 50}}>Installation:</strong><span className="code-snippet">pip install git+https://gitlab.com/umqis/tradable/livelibrary.git</span>
      <div style={{margin: '10px 0px'}}>
        The API supports both paper and live trading, over either HTTPS (from anywhere) or HTTP (from the server only). 
        Depending on where you're running your code, you can use the following endpoints:
      </div>
      <Grid title='Endpoints' entries={{
         'Paper HTTPS Endpoint': 'https://api.qisumich.com:4444',
         'Paper HTTP Endpoint': 'https://api.qisumich.com:4443',
         'Live HTTPS Endpoint': 'https://api.qisumich.com:5555',
         'Live HTTP Endpoint': 'https://api.qisumich.com:5554'
      }}/>
    </>,
    subsections: [],
  },
  {
    title: "Portfolio Operations",
    description: <>
      The API must always be used when performing the following portfolio updates. You can perform these operations using the livelibrary:
      <div style={{width: 410, marginTop: 10}} className="code-snippet"> 
        <div>from livelibrary.portfolio_operations import PortfolioOperations </div>
        <div>ops = PortfolioOperations(endpoint, username, password)</div>
      </div>
    </>,
    subsections: [
        {
          title: "Create a strategy",
          route: "ops.add_strategy",
          description: "Adds a new strategy to be tracked by the portfolio. No trades can be placed until the strategy is created and has money invested in it.",
          params: {
            "strategy_name": "String - The name of the strategy."
          }
        },
        {
          title: "Add an investor",
          route: "ops.add_investor",
          description: "Adds a new investor account to the portfolio.",
          params: {
            "username": "String - The account username.",
            "password": "String - The account password.",
          }
        },
        {
          title: "Deposit to portfolio",
          route: "ops.deposit_money",
          description: "This needs to be called whenever somebody deposits money to our portfolio. This doesn't actually deposit the money \
            to our brokerage account but is necessary for us to track their individual investments.",
          params: {
            "amount": "Float/Int - The amount of money deposited.",
            "investor": "String - The username of the investor that deposited the money."
          }
        },
        {
          title: "Withdraw from portfolio",
          route: "ops.withdraw_from_portfolio",
          description: "This needs to be called whenever somebody withdraws money from our portfolio. This doesn't actually withdraw the money \
            from our brokerage account but is neccessary for us to track their individual investments.",
          params: {
            "amount": 'Float/Int/String - The amount of money withdrawn. If value is a number, withdraws that number from their account. \
              If value is "all", withdraws the rest of the money from their account.',
            "investor": "String - The username of the investor that withdrew the money."
          }
        },
        {
          title: "Invest in a strategy",
          route: "ops.invest_in_strategy",
          description: "This allocates an investor's free cash to a particular strategy, also updating the strategy's current cash/equity.",
          params: {
            "amount": "Float/Int - The amount of money invested.",
            "investor": "String - The username of the investor that invested the money.",
            "strategy_name": "String - The name of the strategy being invested in."
          }
        },
        {
          title: "Withdraw from a strategy",
          route: "ops.withdraw_from_strategy",
          description: "This withdraws money invested in a particular strategy and converts it back into free cash for the investor to \
            allocate elsewhere. The money still remains in the portfolio.",
          params: {
            "amount": 'Float/Int/String - The amount of money withdrawn. If value is a number, withdraws that number from the strategy. \
              If value is "all", withdraws the rest of their money invested in that strategy.',
            "investor": "String - The username of the investor withdrawing the money.",
            "strategy_name": "String - The name of the strategy being withdrawn from."
          }
        }
    ],
  },
  {
    title: "Trading",
    description: <>
      The following functionality should be used for strategy implementation. This documentation assumes that you already have an understanding of how these order types work. 
      For more information, see Alpaca's <a href='https://alpaca.markets/docs/trading-on-alpaca/orders/' style={{color: 'blue'}}>documentation</a>. To use the API Client:
      <div style={{width: 420, marginTop: 10}} className="code-snippet"> 
        <div>from livelibrary.api_client import QisApiClient </div>
        <div>client = QisApiClient(endpoint, username, password, strategy_name)</div>
      </div>
    </>,
    subsections: [
      {
        title: "Stream Prices",
        route: "client.subscribe_symbol",
        description: <>
          Subscribe the server to a live feed of quotes for a particular symbol. Quotes can be retreived using the get_latest_quote route.
          <div><strong>Note: </strong> Due to Alpaca's limitations, quote streaming is only available via the live endpoints. This route will throw an error on the paper API. </div>
        </>,
        params: {
          'symbol': 'String - The symbol (ticker) to price check.',
        },
      },
      {
        title: "Get Latest Quote",
        route: "client.get_latest_quote",
        description: <>
          Get the latest quote from our broker's live feed. This route will only work if: 
          <ol>
            <li>It is called on the live API</li>
            <li>The server has subscribed to the quote feed for this symbol using the subscribe_symbol route.</li>
            <li>The server has received a quote for the symbol since subscribing to the live quote feed.</li>
          </ol>
        </>,
        params: {
          'symbol': 'String - The symbol (ticker) to price check.',
        },
        returns: {
          'bid_price': 'Float - The latest bid price.',
          'ask_price': 'Float - The latest ask price.',
          'bid_size': 'Int - The size of the latest bid.',
          'ask_size': 'Int - The size of the latest ask.',
        }
      },
      {
        title: "Get Price Estimate",
        route: "client.get_price_estimate",
        description: "Get the approximate current value of a stock. This will be less accurate than the live quotes, but works on the paper API and \
          should be fine for less sensitive strategies.",
        params: {
          'symbol': 'String - The symbol (ticker) to price check.',
        },
        returns: {
          'price': 'Float - The price per share.'
        }
      },
      {
        title: "Get Strategy State",
        route: "client.get_strategy",
        description: "Get the server's current state of the strategy.",
        returns: {
          'positions': 'Dict - { SYMBOL: { quantity: Int, price: Float }, ... }',
          'pending_orders': 'Dict - { ORDER_ID: { symbol: String, quantity: Int, buy_sell: String }, ... }',
          'equity': "Float - The strategy's current equity",
          'exposure': "Float - The strategy's current exposure.",
          'cash': "Float - The strategy's free cash.",
        }
      },
      {
        title: "Market Orders",
        route: "client.market_order",
        params: {
          'symbol': 'String - The symbol (ticker) of the stock to buy/sell.',
          'quantity': 'Int - Number of shares to place the order for',
          'buy_sell': 'String - "buy" to buy shares, "sell" to sell.',
          'time_in_force': 'String - Time in force for the order. Options are <day/gtc/ioc/fok>. See Alpaca documentation for more details.'
        },
        returns: {
          'order_id': 'String - The ID of the Order.'
        }
      },
      {
        title: "Stop Orders",
        route: "client.stop_order",
        params: {
          'symbol': 'String - The symbol (ticker) of the stock to buy/sell.',
          'quantity': 'Int - Number of shares to place the order for.',
          'buy_sell': 'String - "buy" to buy shares, "sell" to sell.',
          'time_in_force': 'String - Time in force for the order. Options are <day/gtc/ioc/fok>. See Alpaca documentation for more details.',
          'stop_price': 'Float - The stop price at which to execute the order.'
        },
        returns: {
          'order_id': 'String - The ID of the Order.'
        }
      },
      {
        title: "Limit Orders",
        route: "client.limit_order",
        params: {
          'symbol': 'String - The symbol (ticker) of the stock to buy/sell.',
          'quantity': 'Int - Number of shares to place the order for',
          'buy_sell': 'String - "buy" to buy shares, "sell" to sell.',
          'time_in_force': 'String - Time in force for the order. Options are <day/gtc/ioc/fok>. See Alpaca documentation for more details.',
          'limit_price': 'Float - The limit price at which to execute the order.'
        },
        returns: {
          'order_id': 'String - The ID of the Order.'
        }
      },
      {
        title: "Midprice Limit Orders",
        route: "client.midprice_limit_order",
        description: <>
          This will place a limit order at the midpoint between the bid/ask spread, occasionally replacing the trade until it's filled.
          <div><strong>Note: </strong>This route does not return an order_id because the orders are continuously getting replaced. This can make managing state trickier.</div>
        </>,
        params: {
          'symbol': 'String - The symbol (ticker) of the stock to buy/sell.',
          'quantity': 'Int - Number of shares to place the order for',
          'buy_sell': 'String - "buy" to buy shares, "sell" to sell.',
          'time_in_force': 'String - Time in force for the order. Options are <day/gtc/ioc/fok>. See Alpaca documentation for more details.',
          'patience': 'Int - Number of seconds to wait for the order to fill before replacing it (Default 10).',
          'round_aggressive': "Bool - Aggressive means that if it's a buy it rounds UP.  e.g. bid 1.00, ask 1.03 -> buy at 1.02 (Default True)"
        },
      },
      {
        title: "Stop Limit Orders",
        route: "client.stop_limit_order",
        params: {
          'symbol': 'String - The symbol (ticker) of the stock to buy/sell.',
          'quantity': 'Int - Number of shares to place the order for',
          'buy_sell': 'String - "buy" to buy shares, "sell" to sell.',
          'time_in_force': 'String - Time in force for the order. Options are <day/gtc/ioc/fok>. See Alpaca documentation for more details.',
          'stop_price': 'Float - The stop price at which to execute the order.',
          'limit_price': 'Float - The limit price at which to execute the order.'
        },
        returns: {
          'order_id': 'String - The ID of the Order.'
        }
      },
      {
        title: "Bracket Orders",
        route: "client.bracket_order",
        params: {
          'symbol': 'String - The symbol (ticker) of the stock to buy/sell.',
          'quantity': 'Int - Number of shares to place the order for',
          'buy_sell': 'String - "buy" to buy shares, "sell" to sell.',
          'time_in_force': 'String - Time in force for the order. Options are <day/gtc> for bracket orders. See Alpaca documentation for more details.',
          'entry_type': 'String - The type of order to be placed to enter the position. Options are <limit/market>. If limit, entry_limit_price must also be specified.',
          'profit_price': 'Float - The price at which to exit the position for a profit. If the order is to buy, this must be higher than the stop_price. If the order is to sell, this must be lower than the stop_price.',
          'stop_price': 'Float - The stop price for the stop loss exit order.',
          'stop_limit_price': 'Float (OPTIONAL) - If specified, the stop loss exit will be executed as a stop limit order at the specified limit price',
          'entry_limit_price': 'Float (OPTIONAL) - If entry_type is limit, this must be specified as the limit price of the entry order.',
        },
        returns: {
          'order_id': 'String - The ID of the Order.',
          'legs': '[String, String] - List containing the IDs of the profit and stop loss orders.'
        }
      },
      {
        title: "Trailing Stop Orders",
        route: "client.trailing_stop_order",
        description: "Either trail_price or trail_percent must be specified. Currently Alpaca requires that the trail must be \
              lower than 25% of the stock's value.",
        params: {
          'symbol': 'String - The symbol (ticker) of the stock to buy/sell.',
          'quantity': 'Int - Number of shares to place the order for',
          'buy_sell': 'String - "buy" to buy shares, "sell" to sell.',
          'time_in_force': 'String - Time in force for the order. Options are <day/gtc/ioc/fok>. See Alpaca documentation for more details.',
          'trail_price': 'Float (OPTIONAL) - Value in dollars to trail the highest water mark. A value of 1.0 will have a stop price of (high water mark - 1). ',
          'trail_percent': 'Float (OPTIONAL) - Value in percent to trail the highest water mark. A value of 1.0 will have a stop price of 99% the high water mark.',
        },
        returns: {
          'order_id': 'String - The ID of the Order.'
        }
      },
      {
        title: "Cancel an Order",
        route: "client.cancel_order",
        description: "Cancel an order. Will error if the order was not able to be cancelled.",
        params: {
          'order_id': 'String - The ID of the order to cancel.',
        },
      }
    ]
  }
]


class Documentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightedElement: null,
    }
    this.scroll = this.scroll.bind(this);
  }

  scroll(id) {
    let scrollTo = document.getElementById(id)
    document.getElementById('sectionContainer').scrollTop = scrollTo.offsetTop;
    scrollTo.classList.add('highlight');
    if (this.state.highlightedElement){
      this.state.highlightedElement.classList.remove('highlight');
    }
    this.setState({ highlightedElement: scrollTo });
  }

  render(){
    return (<>
      <a href='/' className="back-x"> X </a>
      <div style={{ display: "flex", flexDirection: "row", height: "100%"}}>
        <div style={{ width: 300, height: "100vh", borderRight: "1px solid lightgray"}}>
          <img src='/Logo.png' style={{ width: 150, display: "block", margin: "10px auto", }} />
          <h1 style={{ textAlign: "center", paddingBottom: 20 }}>Index</h1>
          <div>
            { sections.map(section => <NavSection section={section} scroll={this.scroll}/>) }
          </div>  
        </div>
        <div id="sectionContainer" style={{ padding: "0px 40px", display: "flex", flexDirection: "column", height: "100vh", overflow: "scroll" }}> 
          { sections.map(section => <DocSection section={section}/>) }
        </div>
      </div>
    </>);
  }
}

function NavSection(props) {
  const section = props.section;
  return <div style={{width: "100%", marginBottom: 10}}>
    <div style={{width: "100%", paddingLeft: "10%", fontWeight: "bold", color: "rgba(0,39,94,1)"}} className="nav-item" onClick={() => props.scroll(section.title.replace(' ', ''))}>
      {section.title}
    </div>
    <div style={{ height: 1, width: "80%", margin: "auto", borderTop: "1px solid lightgray"}} />
    {section.subsections.map(subsection => <div style={{marginLeft: "15%"}} className="nav-item" onClick={() => props.scroll(subsection.title.replace(' ', ''))}>{subsection.title}</div>)}
  </div>
}

function DocSection(props) {
  const section = props.section
  return <>
    <h1 style={{ color: "rgba(0,39,94,1)", borderBottom: "1px solid lightgray"}} id={section.title.replace(' ', '')}>{section.title}</h1>
    <div style={{marginBottom: 20, marginTop: 5}}>{section.description}</div>
    <div style={{ marginBottom: 20}}>
      { section.subsections.map(subsection => <Subsection route={subsection} />) }
    </div>
  </>
}


function Subsection(props) {
  const route = props.route;
  return <div className="route-container">
  <div id={route.title.replace(' ', '')} style={{ paddingBottom: 5}}>
    <h2 style={{ fontSize: 18, letterSpacing: 0, display: "inline", marginRight: 10, width: 100}} >{route.title}</h2>
    {route.route && <span style={{ fontWeight: 'bold' }} className="code-snippet">{route.route}( <span style={{fontWeight: 'normal', fontStyle: 'italic'}}>{route.params && Object.keys(route.params).join(', ')}</span> )</span>}
  </div>
  {route.description && <div>{route.description}</div>}
  {route.params && <Grid title='Params' entries={route.params} />}
  {route.returns && <Grid title='Returns' entries={route.returns} />}
  </div>
}

function Grid(props){
  let w1 = 0, w2 = 0;
  Object.entries(props.entries).map(entry => {
    if (entry[0].length * 8 + 20 > w1) w1 = Math.min(entry[0].length * 8 + 20, 400);
    if (entry[1].length * 8 > w2) w2 = Math.min(entry[1].length * 8, 500);
  })
  return <>
  <h3 style={{paddingTop: 10}}>{props.title}</h3>
  <div style={{paddingLeft: 30, width: w1 + w2, display: "grid", gap: "10x 0px", gridTemplateColumns: w1 + "px " + w2 +  "px"}}> 
    {Object.entries(props.entries).map((entry, i) => {
      return <>
        <div style={{minHeight: 30, paddingTop: 10,  borderBottom: i == Object.entries(props.entries).length - 1 ? "" : "1px solid lightgray", borderRight: '1px solid lightgray'}}><span style={{width: "auto"}}>{entry[0]}</span></div>
        <span style={{borderBottom: i == Object.entries(props.entries).length - 1 ? "" : "1px solid lightgray", paddingLeft: 10, paddingTop: 10}}>{entry[1]}</span>
      </>;
    })}
  </div></>
}

    
  export default Documentation;