import React from "react";
import InternalContext from "./InternalContext";
import Loader from "react-loader-spinner";

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadOpen: false,
    }
    this.openDownloader = this.openDownloader.bind(this);
    this.closeDownloader = this.closeDownloader.bind(this);
  }

  openDownloader(e) {
    e.preventDefault();
    if (this.state.downloadOpen) return;
    //this.state.downloadOpen = true; TODO: Uncomment when librarian is finished
    //this.setState(this.state);
  }

  closeDownloader(e) {
    e.preventDefault();
    if (!this.state.downloadOpen) return;
    this.state.downloadOpen = false;
    this.setState(this.state);
  }

  render() {
    return (<InternalContext.Consumer>{({ context, setContext }) => (<>
      <a href="/documentation" style={{ position: "fixed", right: 50, top: 20, color: "blue", textDecoration: "underline"}}>API Reference</a>
      {//<a href="#" onClick={this.openDownloader} style={{ position: "fixed", right: 50, top: 40, color: "blue", textDecoration: "underline"}}>Download Data</a> TODO: Uncomment when librarian is finished
      }
      {this.state.downloadOpen && <Downloader closeDownloader={this.closeDownloader} state={this.state}/>}
      <StrategyList title={"Strategies"} strats={context.strats}/>
      <InvestorList title={"Investors"} investors={context.investors}/>
      <StrategyList title={"Paper Strategies"} strats={context.paperStrats} paper={true}/>
      <InvestorList title={"Paper Investors"} investors={context.paperInvestors}/>
    </>)}</InternalContext.Consumer>);
  }
}

class Downloader extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      tickers: [],
      start: null,
      end: null,
      jobId: null,
    }
    this.addTicker = this.addTicker.bind(this);
    this.clearTickers = this.clearTickers.bind(this);
    this.setStart = this.setStart.bind(this);
    this.setEnd = this.setEnd.bind(this);
    this.download = this.download.bind(this);
  }

  addTicker(e){
    e.preventDefault()
    let ticker = document.getElementById('ticker').value.toUpperCase();
    this.state.tickers.push(ticker);
    this.setState(this.state);
  }

  clearTickers(e){
    e.preventDefault();
    this.state.tickers = [];
    this.setState(this.state);
  }

  setStart(e){
    this.state.start = e.target.value;
    this.setState(this.state);
  }

  setEnd(e){
    this.state.end = e.target.value;
    this.setState(this.state);
  }

  download(e){
    e.preventDefault();
    this.state.jobId = 69;
    this.setState(this.state);
  }

  render(){
    console.log(this.state);
    let content = null;
    if (this.state.jobId){
      content = <>
        <h2>Download Initiated</h2>
        <div style={{ margin: 'auto', width: 300}}>A download link will be emailed to you when your data is assembled.</div>
        <button style={{display: 'block', margin: 'auto'}} onClick={this.props.closeDownloader}>Close</button>
      </>
    } else {
      content = <>
        <h2>Download Data</h2>
        <form style={{ display: 'flex', width: 300, minHeight: 200, margin: 'auto', flexDirection: 'column', justifyContent: 'space-around'}}>
          <label>Start Date</label>
          <input type='date' value={this.state.start} onChange={this.setStart}/>
          <label>End Date</label>
          <input type='date' value={this.state.end} onChange={this.setEnd}/>
          <div className='space-around'><input id='ticker' style={{textTransform: 'uppercase'}}/><button onClick={this.addTicker}>Add Ticker</button><button onClick={this.clearTickers}>Clear</button></div>
          <div>Tickers: {this.state.tickers.join(', ')}</div>
          <div className='space-around'><button onClick={this.download}>Download</button><button onClick={this.props.closeDownloader}>Cancel</button></div>
        </form>
      </>
    }
    return <div className='round-border' style={{position: 'fixed', top: '40%', left: '50%', width: 400, height: 250, marginLeft: -200, marginTop: -125, background: 'white', zIndex: 69, overFlow: 'scroll'}}>
      {content} 
    </div>;
  }
}
  
function StrategyList(props) {
  return (<>
    <h1 style={{ textAlign: "center", padding: '10px 0px', color: 'rgba(0,39,94,1)'}}>{props.title}</h1>
    {(props.strats && props.strats.length) ? 
    <div style={{ margin: "10px auto", width: 700, display: "grid", gap: 10, gridTemplateColumns: "230px 230px 230px"}}>
      {props.strats.map((strat, ind) => 
        <a href={'/strategy/' + (props.paper ? 'paper/' : '') + strat.strategy} className='dashboard-item'>
            <div style={{fontSize: 18}}>{strat.strategy}</div>
            <div style={{padding: '0px 10px', textAlign: 'left'}}>
              Equity: 
              <span style={{float: 'right', color: 'green'}}>${strat.equity.toFixed(2)}</span>
            </div>
            <div style={{padding: '0px 10px', textAlign: 'left'}}>
              Exposure: 
              <span style={{float: 'right', color: strat.exposure > (2 * strat.equity) ? 'red' : 'green'}}>${strat.exposure.toFixed(2)}</span>
            </div>
            <div style={{padding: '0px 10px', textAlign: 'left'}}>
              Investors: 
              <span style={{float: 'right'}}>{Object.keys(strat.investors).length}</span>
            </div>
        </a>
      )}
    </div>
    : <div style={{ width: 70, margin: 'auto' }}><Loader type="ThreeDots" color="#00275e" height={70} width={70} /></div>}</>)
}

function InvestorList(props) {
  return (<>
    <h1 style={{ textAlign: "center", padding: '10px 0px', color: 'rgba(0,39,94,1)'}}>{props.title}</h1>
    {(props.investors && props.investors.length) ? 
    <div style={{ margin: "10px auto", width: 700, display: "grid", gap: 10, gridTemplateColumns: "230px 230px 230px"}}>
      {props.investors.map((investor, ind) => {
        let equity = investor.cash + Object.keys(investor.investments).reduce((sum,key)=> sum+investor.investments[key], 0);
        return <a className='dashboard-item' href={'/investor/' + (props.paper ? 'paper/' : '') + investor.username}>
            <div style={{ fontSize: 18 }}>{investor.username}</div>
            <div style={{padding: '0px 10px', textAlign: 'left'}}>
              Equity: 
              <span style={{float: 'right', color: 'green'}}>${equity.toFixed(2)}</span>
            </div>
            <div style={{padding: '0px 10px', textAlign: 'left'}}>
              Free Cash: 
              <span style={{float: 'right', color: 'green'}}>${investor.cash.toFixed(2)}</span>
            </div>
        </a>
      })}
    </div>
    : <div style={{ width: 70, margin: 'auto' }}><Loader type="ThreeDots" color="#00275e" height={70} width={70} /></div>}</>)
}
  
export default Dashboard;
  