import React from "react";
let defaultContext = {
  strats: [],
  paperStrats: [],
  investors: [],
  paperInvestors: [],
  docsOpen: false,
  username: "",
  password: "",
  authToken: "",
  error: ""
};

export default React.createContext({
  context: defaultContext,
  setContext: () => {}
});