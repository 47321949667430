import React from 'react';
import InternalContext from "./InternalContext";
import Loader from "react-loader-spinner";
import { LineChart, BarChart, Bar, CartesianGrid, Line, XAxis, YAxis, Tooltip } from 'recharts';
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


class Investor extends React.Component {
  static contextType = InternalContext;
  constructor(props) {
    super(props);
  }

  render() {
    let investor = null;
    let investorName = this.props.match.params[0];
    if (window.location.pathname.indexOf('/paper/') != -1){
      for (const fella of this.context.context.paperInvestors){
        if(fella.username == investorName){
          investor = fella;
        }
      }
    } else {
      for (const fella of this.context.context.investors){
        if(fella.username == investorName){
          investor = fella;
        }
      }
    }
    if (!investor ) {
      return <InternalContext.Consumer>{({ context, setContext }) => (<>
        <a className="back-arrow" href='/'>
          <img src="/arrow.png" style={{ width: 30, height: 30, margin: "auto", display: "block", marginTop: 5}}/>
        </a>
        <div style={{ width: 70, margin: 'auto' }}><Loader type="ThreeDots" color="#00275e" height={70} width={70} /></div>
      </>)}</InternalContext.Consumer>
    }
    let equity = investor.cash + Object.keys(investor.investments).reduce((sum,key)=> sum+investor.investments[key], 0);
    let equityLogs = investor.logs.map(log => {
      let date = new Date(log.timestamp * 1000);
      return { Date: date, Equity: log.equity}
    })
    let ticks = [];
    return (<InternalContext.Consumer>{({ context, setContext }) => (<>
      <a className="back-arrow" href='/'>
        <img src="/arrow.png" style={{ width: 30, height: 30, margin: "auto", display: "block", marginTop: 5}}/>
      </a>
      <div id="wrapper" style={{margin: "auto", width: 1000}}> 
				      <div id="content">
					      <div >
						      <div>
							      <div style={{ margin: "auto", textAlign: "center" }}>
								      <h2 style={{ color: 'rgba(0,39,94,1)'}}>Investor Summary - {investor.username}</h2>
								      <p>
                        Equity: ${equity.toFixed(2)} &nbsp; | &nbsp; Free Cash: ${investor.cash.toFixed(2)} &nbsp;
                      </p>
							      </div>
						      </div>
					      </div>
                <div className="tear-row">
							    <div className="tear-box" style={{ overflow: 'scroll' }}>
                      <h2 style={{ textAlign: 'center' }}> Investments</h2>
                      {!Object.keys(investor.investments).length && <div style={{textAlign: 'center'}}>No Current Investments.</div>}
                      {Object.keys(investor.investments).map(pos => 
                      <div className="tear-entry">
                        <span>{pos}</span>
                        <span style={{ fontWeight: 'bold' }}>${investor.investments[pos].toFixed(2)}</span>
                      </div>
                    )}
                  </div>
                <div className="tear-box" style={{ maxHeight: 350, overflow: 'scroll' }}>
                      <h2 style={{ textAlign: 'center' }}> Activity</h2>
                      {!investor.activity.length && <div style={{textAlign: 'center'}}>No Activity on Record.</div>}
                      {investor.activity.map(event => 
                      <div className="tear-entry">
                        <div>
                        {event.strategy ? <div><span style={{ fontWeight: 'bold'}}>{event.type == 'deposit' ? 'Investment' : 'Withdrawal'}</span> - {event.strategy}</div> : <div><span style={{ textTransform: 'capitalize', fontWeight: 'bold'}}>{event.type}</span> - Portfolio</div>}
                        <div>{new Date(event.timestamp * 1000).toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'})}</div>
                        </div>
                        <span style={{ fontWeight: 'bold'}}>${event.amount}</span>
                      </div>
                    )}
                  </div>
              </div>
          {equityLogs.length > 0 && <div id="investment-chart" className="round-border" style={{ width: 970 }}>
						<h2>Equity History</h2>
            <div className="round-border" style={{ overflow: "hidden"}}>
              <LineChart width={960} height={400} data={equityLogs} margin={{ right: 30, top: 15, bottom: 5, left: 5}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis height={60} dataKey='Date' label='Date' interval={10} tickLine={false} tickFormatter={(tick) => { let label = months[tick.getMonth()] + ' ' + tick.getFullYear(); if (ticks.indexOf(label) == -1) { ticks.push(label); return label;} return ' '}}/>
                <YAxis label={{ value: 'Equity', angle: -90, position: 'insideLeft' }} width={60} tickFormatter={tick => '$' + tick.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/>
                <Tooltip formatter={(value, name, props) => '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} labelFormatter={tick => ("0" + (tick.getMonth() + 1)).slice(-2) + '/' + ('0' + tick.getDate()).slice(-2) + '/' + tick.getFullYear()}/>
                <Line type='monotone' dataKey='Equity' stroke='#00275e' />
              </LineChart>
						</div>
					</div>}
				</div>
      </div>
      </>)}</InternalContext.Consumer>);
  }
}

export default Investor;
