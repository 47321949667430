import React from 'react';
import { LineChart, Bar, CartesianGrid, ComposedChart, ReferenceLine, Line, XAxis, YAxis, Tooltip } from 'recharts';
import InternalContext from './InternalContext'
import Loader from "react-loader-spinner";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const statistics = ['avgMonthlyReturn', 'monthlyStd', 'maxLossMonth', 'gainToPain'];
const titles = {
  avgMonthlyReturn: "Average Monthly Return",
  monthlyStd: "Standard Deviation",
  maxLossMonth: "Max Loss Month",
  gainToPain: "Gain to Pain",
}

class Strategy extends React.Component {
  static contextType = InternalContext;
  constructor(props) {
    super(props);
    
    this.state = {
      strategy: null,
      ytdData: [0,0,0,0,0]
    };
  }

  initializeStrategy() {
    let strategy = null;
    let strategyName = this.props.match.params[0];
    if (window.location.pathname.indexOf('/paper/') != -1){
      for (const strat of this.context.context.paperStrats){
        if(strat.strategy == strategyName){
          strategy = strat;
        }
      }
    } else {
      for (const strat of this.context.context.strats){
        if(strat.strategy == strategyName){
          strategy = strat;
        }
      }
    }
    return strategy;
  }

  formatMonthlyReturns(strategy) {
    let monthlyReturns = []; 
    let sampleInvestmentData = [];
    let sampleInvestment = 1000;
    let _ = [...Array(12).keys()].reverse().map(ind => {
      let date = new Date();
      date.setDate(1)
      date.setMonth(date.getMonth() - ind);
      if (ind == 11) sampleInvestmentData.push({ x: new Date(date), Equity: 1000});
      let month = months[date.getMonth()];
      let year = date.getFullYear();
      let returns_ind = strategy.performance.monthlyReturns.length - 1 - ind
      let returns = (returns_ind < 0 ? 0 : strategy.performance.monthlyReturns[returns_ind]);
      sampleInvestment *= (1 + (returns / 100.0));
      monthlyReturns.push({ date: month + ' ' + year, returns: returns});
      if (ind == 0) sampleInvestmentData.push({ x: new Date(), Equity: sampleInvestment})
      else {
        date.setMonth(date.getMonth() + 1)
        sampleInvestmentData.push({ x: date, Equity: sampleInvestment})
      }
    });
    return [ monthlyReturns, sampleInvestmentData ];
  }

  render() {
    let strategy = this.initializeStrategy();
    if (!strategy) {
      return <div style={{ width: 70, margin: 'auto' }}><Loader type="ThreeDots" color="#00275e" height={70} width={70} /></div>
    }
    else if (!strategy.performance || !Object.keys(strategy.performance).length) {
      return <InternalContext.Consumer>{({ context, setContext }) => (<>
        <a className="back-arrow" href='/'>
          <img src="/arrow.png" style={{ width: 30, height: 30, margin: "auto", display: "block", marginTop: 5}}/>
        </a>
        <div style={{ margin: "auto", textAlign: "center", color: 'rgba(0,39,94,1)' }}>
            <h2>Strategy Summary - {strategy.strategy}</h2>
            <p>
              Equity: ${strategy.equity.toFixed(2)} &nbsp;
            </p>
        </div>
        <div style={{ fontWeight: 'bold', textAlign: 'center', margin: 10}}>Performance data for this strategy has not yet been calculated. Check again after the next market day.</div>
        <div class='tear-row'>
          <div class="tear-box" >
            <h2>Positions</h2>
            <div style={{ fontWeight: 'bold', textAlign: 'center'}}>Current Exposure: ${strategy.exposure.toFixed(2)}</div>
            {Object.keys(strategy.positions).map(pos => 
              <div className="tear-entry">
                <span>{pos}</span>
                <span>{strategy.positions[pos].quantity}</span>
              </div>
            )}
          </div>
        </div>
      </>)}</InternalContext.Consumer>
    }
    
    let [ monthlyReturns, sampleInvestmentData ] = this.formatMonthlyReturns(strategy);
    strategy.performance.performanceByStock.map(perf => { perf.line = 0 })
    let lastUpdated = new Date(strategy.performance.lastUpdated * 1000);
    return (<InternalContext.Consumer>{({ context, setContext }) => (<>
      <a className="back-arrow" href='/'>
        <img src="/arrow.png" style={{ width: 30, height: 30, margin: "auto", display: "block", marginTop: 5}}/>
      </a>
      <div style={{background: "#fbfbfb"}}>
      <div id="wrapper" style={{margin: "auto", width: 1000}}> 
				<div id="content">
          <div style={{color: 'gray', position: 'absolute', right: 20, top: 20}}>
            <div>Performance Last Updated: </div>
            <div style={{fontWeight: 'bold'}}>{new Date(strategy.performance.lastUpdated * 1000).toLocaleString('en-US', { timeZone: 'EST' })} EST</div>
          </div>
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h2 style={{ color: 'rgba(0,39,94,1)' }}>Strategy Summary - {strategy.strategy}</h2>
            <p>
              Equity: ${strategy.equity.toFixed(2)} &nbsp; | &nbsp; Last 30 Days: {strategy.performance.past30Days} &nbsp; | &nbsp; Last 365 Days: {strategy.performance.past365Days} &nbsp;
            </p>
          </div>
				  <div class="post">
						<div>
              <div className="tear-row" >
							  <div class="tear-box" >
								  <h2>Statistics</h2>
                  {statistics.map(stat => 
                    <div className="tear-entry">
                      <span>{titles[stat]}</span>
                      <span>{strategy.performance[stat]}</span>
                    </div>
                  )}
							  </div>
                <div class="tear-box" >
                  <h2>Positions</h2>
                  <div style={{ fontWeight: 'bold', textAlign: 'center'}}>Current Exposure: ${strategy.exposure.toFixed(2)}</div>
                  {Object.keys(strategy.positions).map(pos => 
                    <div className="tear-entry">
                      <span>{pos}</span>
                      <span>{strategy.positions[pos].quantity}</span>
                    </div>
                  )}
                </div>
              </div>
						</div>
					</div>
          <div className="post">
            <div className='tear-box' style={{ width: 1000, height: 110 }}>
              <div className="entry">
                <h2>Last 12 Months</h2>
                <div style={{ display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "space-around", paddingTop: 5}}>
                {monthlyReturns.map((ret, ind) => {
                  return (
                  <div style={{ width: 80, textAlign: 'center', borderRight: ind == 11 ? '' : '1px solid black'}}>
                    <div>{ret.date}</div>
                    <div>{ret.returns.toFixed(2)}%</div>
                  </div>);
                })}
                </div>
              </div>
            </div>
          </div>
          <div class="post">
              <div className="chart-box">
                <h2>Performance Attribution - Past 30 Days</h2>
                <div className="round-border" style={{ overflow: "hidden"}}>
                  <ComposedChart width={970} height={400} data={strategy.performance.performanceByStock}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="stock" />
                    <YAxis />
                    <Tooltip formatter={(val, name, props) => val + '%'}/>
                    <Bar dataKey="return" fill="#00275e" />
                    <ReferenceLine y={0} stroke='#000000'/>
                  </ComposedChart>
              </div>
						</div>
					</div>
          <div id="investment-chart" className="round-border" style={{ width: 970 }}>
						<h2>Value of an Initial $1,000 Investment</h2>
            <div className="round-border" style={{ overflow: "hidden"}}>
              <LineChart width={960} height={400} data={sampleInvestmentData} margin={{ right: 30, top: 15, bottom: 5, left: 5}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis height={60} dataKey='x' label='Date' tickFormatter={tick => months[tick.getMonth()] + ' ' + tick.getFullYear()}/>
                <YAxis label={{ value: 'Value', angle: -90, position: 'insideLeft' }} width={60} tickFormatter={tick => '$' + tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/>
                <Tooltip formatter={(value, name, props) => '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} labelFormatter={tick => ("0" + (tick.getMonth() + 1)).slice(-2) + '/' + ('0' + tick.getDate()).slice(-2) + '/' + tick.getFullYear()}/>
                <Line type='monotone' dataKey='Equity' stroke='#00275e' />
                <ReferenceLine strokeDasharray='3 3' y={1000} stroke='#000000'/>
              </LineChart>
						</div>
					</div>
				</div>
			</div>
      </div>
      </>)}</InternalContext.Consumer>);
  }
}

export default Strategy;
