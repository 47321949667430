import React from "react";
import Investor from "./Investor";
import Strategy from "./Strategy";
import Dashboard from "./Dashboard"
import Documentation from "./Documentation"
import InternalContext from "./InternalContext"
import './styles.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

let defaultContext = {
  strats: [],
  paperStrats: [],
  investors: [],
  paperInvestors: [],
  docsOpen: false,
  username: "",
  password: "",
  authToken: "",
  error: ""
};

const api = 'https://api.qisumich.com:5555';
const paperApi = 'https://api.qisumich.com:4444';
//const api = 'https://207.244.232.61:5554';

//TODO: origin might need a change
let getOptions = {
  method: 'GET',
  headers: {
      'Origin': api,
      'Access-Control-Request-Method': 'GET'
  }
};
let postOptions = {
  method: 'POST',
  headers: {
      'Content-Type': 'application/json',
      'Origin': api,
      'Access-Control-Request-Method': 'POST'
  }
};

class InternalSite extends React.Component {
  constructor(props) {
    super(props);

    this.setContext = context => {
      this.setState({ context });
    };
    this.state = {
      context: defaultContext,
      setContext: this.setContext
    }

    if (window.location.pathname != '/login'){
      let creds = JSON.parse(localStorage.getItem('creds'));
      if (!creds || creds.timestamp < (Date.now() - (1000 * 60 * 60 * 8)) || !creds.username || !creds.authToken){
        window.location.pathname = '/login'
      }
      this.state.context.username = creds.username;
      this.state.context.authToken = creds.authToken;
    }

    this.storeCreds = this.storeCreds.bind(this);
    this.login = this.login.bind(this);
    this.setUsername = this.setUsername.bind(this);
    this.setPassword = this.setPassword.bind(this);
  }

  componentDidMount() {
    //TODO: only try this if route is / or /strategy/* i think. This could also get deferred to individual components rather than be done here.
    this.getStrats();
  }

  storeCreds() {
    let creds = {
      username: this.state.context.username,
      authToken: this.state.context.authToken,
      timestamp: Date.now()
    }
    localStorage.setItem("creds", JSON.stringify(creds))
  }

  async login(event) {
    event.preventDefault();
    this.state.context.error = "";

    postOptions.body = JSON.stringify({
      username: this.state.context.username,
      password: this.state.context.password
    });
    try {
      let result = await fetch(api + '/login', postOptions)
      if (result.status != 200) {
        let { error } = await result.json();
        this.state.context.error = error;
        this.setState(this.state);
      } else {
        let { token } = await result.json();
        this.state.context.authToken = token;
        this.setState(this.state);
        this.storeCreds();
        window.location.pathname = '/'
      }
    } catch (e) {
      console.log(e);
      this.state.context.authToken = "";
      this.state.context.error = "An error occured."
      this.setState(this.state);
    }
    
  }

  //TODO: if ever get 401 error or whatever, log out...
  async getStrats() {
    this.state.context.strats = [];
    this.state.context.paperStrats = [];
    if (!this.state.context.authToken || !this.state.context.username) {
      this.setState(this.state);
      //window.location.pathname = '/login';
      return;
    }
    getOptions.headers['Authorization'] = this.state.context.username + ":" + this.state.context.authToken;
    try {
      let result = await fetch(api + '/get-strategies', getOptions);
      if (result.status == 401){
        this.state.authToken = '';
        this.setState(this.state);
        window.location.pathname = '/login';
        return;
      }
      this.state.context.strats = (await result.json()).strats;
      this.state.context.investors = (await (await fetch(api + '/get-investors', getOptions)).json()).investors;
    } catch {}
    try {
      this.state.context.paperStrats = (await (await fetch(paperApi + '/get-strategies', getOptions)).json()).strats;
      this.state.context.paperInvestors = (await (await fetch(paperApi + '/get-investors', getOptions)).json()).investors;
    } catch (e){}
    this.setState(this.state);
   
  }

  setUsername(event) {
    let username = event.target.value;
    this.state.context.username = username;
    this.setState(this.state);
  }

  setPassword(event){
    let password = event.target.value;
    this.state.context.password = password;
    this.setState(this.state);
  }

  render() {
    return <InternalContext.Provider value={this.state}>
    <Router>
      <Switch>
        <Route path="/documentation" component={Documentation}/>
        <Route path='/strategy/paper/*' component={Strategy} />
        <Route path='/strategy/*' component={Strategy} />
        <Route path='/investor/paper/*' component={Investor} />
        <Route path='/investor/*' component={Investor} />
        <Route path='/login'>
        <>
        <img src="/Logo.png" style={{ width: 230, margin: "20px auto", display: "block"}}/>
        <div style={{ height: 20, textAlign: "center", color: "red" }}>{this.state.context.error}</div>
        <div style={{ width: 500, margin: "auto"}}>
          <form style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: 200}} onSubmit={this.login}>
            <label style={{ fontWeight: "bold", fontSize: 14}}>Username</label>
            <input style={{width: 500, height: 30, border: "1px solid #AAAAAA", borderRadius: 5}} value={this.state.username} onChange={this.setUsername}></input>
            <label style={{ fontWeight: "bold", fontSize: 14}}>Password</label>
            <input style={{width: 500, height: 30, border: "1px solid #AAAAAA", borderRadius: 5 }} type="password" value={this.state.password} onChange={this.setPassword}></input>
            <button style={{ width: 504, height: 35, color: "white", background: "rgba(0,39,94,1)", border: "none", borderRadius: 5, fontWeight: "600", fontSize: 14, letterSpacing: 1.1}}>Login</button>
          </form>
        </div>
      </>;
        </Route>
        <Route path='/*' component={Dashboard}/>
      </Switch>
    </Router>
    </InternalContext.Provider>
  }
}
  
export default InternalSite;
  